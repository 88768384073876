@import "main.scss";

@font-face {
    font-family: 'AvantGarde LT Book';
    src: url("/assets/fonts/AvantGardeGothicLT-Book.woff2") format("woff2"),
        url("/assets/fonts/AvantGardeGothicLT-Book.woff") format("woff"),
        url("/assets/fonts/AvantGardeGothicLT-Book.ttf") format("ttf");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.standard-container {
    margin: 0 auto;
    max-width: $standard-width;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-50px);
    }
}

.bounce {
    animation: bounce 0.8s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

html {
    font-family: sans-serif;
    font-size: 100%;
    padding: 0;
    margin: 0;
    max-width: 100vw;

    body {
        margin: 0;
        padding: 0;
        width: 100%;
        background-color: black;

        h1,
        h2 {
            font-family: "AvantGarde LT Book", sans-serif;
            font-weight: 400;
        }

        h1 {
            color: $secondary-color;
            font-size: 3rem;
            margin: 0;

            @media screen and (max-width: 425px) {
                font-size: 2.5rem;
            }

            @media screen and (max-width: 320px) {
                font-size: 2rem;
            }
        }

        h2 {
            font-size: 2.5rem;
            text-transform: uppercase;
            margin-block-start: 0;

            @media screen and (max-width: 425px) {
                font-size: 2rem;
            }

            @media screen and (max-width: 320px) {
                font-size: 1.5rem;
            }
        }

        p {
            color: $accent-color;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5;
        }

        header#hero {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background-size: 1920px 1080px;
            background: url("/assets/images/hero/squires-lane-1920x1080.jpg") no-repeat center center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            height: 100vh;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            position: relative;
            text-align: center;
            -webkit-transition: 3s;
            -o-transition: 3s;
            transition: 3s;
            width: 100%;

            #logo {
                height: auto;
                margin-top: 4rem;
                max-width: 4rem;
                z-index: 2;

                @media screen and (max-width: 40em) {
                    margin-top: 2em;
                }
            }


            .hero-text {
                margin: 0 1rem;
                z-index: 2;

                h1 {
                    color: $primary-color;
                }
            }

            #scroll-down {
                margin-bottom: 4rem;
                z-index: 2;

                span {
                    color: $primary-color;
                    font-size: 2rem;
                }
            }

            .social-icons {
                position: absolute;
                right: 4rem;
                top: 4rem;
                z-index: 2;

                @media screen and (max-width: 40em) {
                    right: 2em;
                    top: 2em;
                }

                @media screen and (max-width: 425px) {
                    right: 32px;
                }

                .instagram-icon {
                    border-radius: 100%;
                    display: block;
                    height: 48px;
                    position: relative;
                    transition: background-color, .3s ease-out;
                    width: 48px;

                    #instagram {
                        height: 24px;
                        left: 50%;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 24px;
                    }
                }

                .instagram-icon:hover {
                    background-color: black;
                }
            }

            .overlay {
                background: linear-gradient(to bottom, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, .5) 100%);
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }

        main {

            .standard-container {
                padding: 7em 2.1875em;
            }

            .background-white {
                background-color: white;
            }

            article {

                section {

                    #about {
                        background-color: white;
                        padding: 0;

                        h2 {
                            color: $secondary-color;
                        }
                    }

                    #portfolio {
                        background: $accent-color;

                        h2,
                        p {
                            color: $primary-color;
                        }

                        .standard-container {
                            padding: 7em 2.1875em 4em;
                        }

                        .slider {
                            padding: 0 0 7em 0;
                            height: 256px;
                            width: 100%;

                            @media screen and (max-width: 1210px) {
                                padding: 4em 0;
                            }

                            .carousel-container {
                                height: 256px;
                                position: relative;
                                max-width: 100%;

                                @media screen and (max-width: 1040px) {
                                    height: 192px;
                                }

                                .owl-carousel {
                                    height: 256px;
                                    max-width: 100%;

                                    .owl-item {
                                        max-width: 256px;
                                    }
                                }

                                .owl-dots {
                                    display: none;
                                    bottom: -20px;
                                    position: absolute;
                                    text-align: center;
                                    width: 100%;
                                }

                                .owl-nav {
                                    position: absolute;
                                    top: 40%;
                                    transform: translateY(-40%);
                                    width: 100%;
                                    z-index: 2;

                                    @media screen and (max-width: 999px) {
                                        top: 50%;
                                        transform: translateY(-50%);
                                    }

                                    @media screen and (max-width: 768px) {
                                        display: none;
                                    }

                                    .owl-prev,
                                    .owl-next {
                                        align-items: center;
                                        background-color: rgba(0, 0, 0, .8);
                                        border: 0;
                                        border-radius: 50%;
                                        cursor: pointer;
                                        display: flex;
                                        height: 64px;
                                        justify-content: center;
                                        position: absolute;
                                        padding: 0;
                                        width: 64px;

                                        span {
                                            color: #c5b294;
                                            font-size: 2rem;
                                            line-height: 100%;
                                            margin: 0;
                                            padding: 0;
                                        }
                                    }

                                    .owl-prev {
                                        left: 4px;
                                    }

                                    .owl-next {
                                        right: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        footer {
            background: black;
            padding: 7em 0;

            @media screen and (max-width: 1210px) {
                padding: 7em 2.1875em;
            }

            #footer-menu {
                margin: 0 auto;
                max-width: $standard-width;

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }

                li {
                    margin: 0;
                }

                li:not(:last-of-type) {
                    margin-bottom: 1em;
                }

                h4 {
                    color: $primary-color;
                    font-family: "AvantGarde LT Book", sans-serif;
                    font-size: 1.2rem;
                    font-weight: 400;
                    margin: 0;
                    text-transform: uppercase;
                }

                a,
                span {
                    color: $primary-color;
                    font-size: 1rem;
                    line-height: 0;
                }

                a {
                    text-decoration: none;
                }
            }
        }

        #preview {
            background: rgba(0, 0, 0, .8);
            display: none;
            height: 100vh;
            left: 0;
            position: fixed;
            top: 0;
            width: auto;
            z-index: 10;

            .image-container {
                margin: 0 auto;
                max-width: 1280px;
                position: relative;

                img {
                    height: auto;
                    position: fixed;
                    left: 50%;
                    max-height: 100vh;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 95%;
                    z-index: 11;
                }

                .close-preview {
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    background: rgba(0, 0, 0, .5);
                    border-radius: 100%;
                    bottom: 3%;
                    color: $primary-color;
                    cursor: pointer;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    font-size: 3rem;
                    height: 20px;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                            justify-content: center;
                    left: 50%;
                    padding: 20px;
                    position: fixed;
                    -webkit-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                            transform: translateX(-50%);
                    -webkit-transform: translateY(-3%);
                    -ms-transform: translateY(-3%);
                            transform: translateY(-3%);
                    -webkit-transition: background .3s ease;
                    -o-transition: background .3s ease;
                    transition: background .3s ease;
                    width: 20px;
                    z-index: 12;

                    &:hover {
                        background: black;
                    }

                    @media screen and (max-width: 600px) {
                        bottom: 18%;
                        left: 45%;
                        transform: translateY(-18%);
                    }

                    @media screen and (max-width: 375px) {
                        bottom: 25%;
                        left: 45%;
                        transform: translateY(-25%);
                    }
                }
            }
        }
    }
}